export const ITEM_TITLES = ["lost", "found"];

export const ITEM_TYPES = [
  "keys",
  "visa",
  "picture",
  "animal",
  "clothes",
  "book",
  "electric",
  "phone",
  "bag",
  "jewelry",
  "other",
];
